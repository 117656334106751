/* eslint-disable no-console */
import React, { PureComponent } from 'react';
import jmap from 'jmap.js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MapUiKit from '@jibestream-dev/jmap-mapui-kit';
import DeviceKit from '@jibestream-dev/jmap-device-kit';
import NavigationKit from '@jibestream-dev/jmap-navigation-kit';
import ZoneKit from '@jibestream-dev/jmap-zone-kit';
import { Button } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { push } from 'connected-react-router';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import { setMapDevices } from '../../actions/lists';
import { getDevice, getMall, getTotemData } from '../../config/kiosk';
import { setStatus } from '../../actions/findYourCar';
import './centre-map.scss';
import { showHideMap, setLoadingMap } from '../../actions/map';
import envVariables from '../../config/envVariables';
import { ACTION, CATEGORY } from '../../constants/googleAnalytics';
import { getWaypointBySuc, isCategoryServices } from '../../utils/jibestream';
import { replaceCharacters } from '../../utils/generalFunction';
import iconQR from '../../assets/images/QRAraucoPlus/QRIcon.png';

import ButtonFloor from './components/ButtonFloor';
import ModalAnimations from './components/ModalAnimation';
import './LenguaDeGato.scss';
import {
  ESCALATOR,
  STAIRS,
  ELEVATOR,
  FIRST_FLOOR,
  CONFIG,
  MAP_SESSION_PAUSE_AFTER_LOAD_IN_SECONDS,
  BUTTONS_FLOORS,
  MAP_HOLDER_CONTROL,
  WAYFINDING_PATH,
  PUBLIC_PROFILE
} from './constants';
import {
  STYLE_HIGH_LIGHT,
  ACTIVE_STYLE,
  IN_ACTIVE_STYLE,
  PATH_STYLE,
  COLOR_RED_STORE,
  ICON_START,
  ICON_END
} from './styles';
import ModalError from './ModalError';
import ModalQR from './ModalQRAraucoPlus/ModalQR';

const highlight = new jmap.Style(STYLE_HIGH_LIGHT);
const urlQRA = envVariables.urlAraucoPlusQR;

class CentreMap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,
      showLevelButtons: false,
      activeFloor: FIRST_FLOOR,
      showInstructions: false,
      pathToStore: [],
      errorText: '',
      openModal: false,
      isAnimatingPath: false,
      setOpen: false,
      messageModal: false,
      moverId: 0,
      statusModalQR: false,
      statusSecondModalQR: false,
      urlQR: false
    };

    this.CONTROL = null;
    this.VENUE = null;
    this.CORE = null;
    this.DeviceKit = null;
    this.NavigationKit = null;
    this.MapUiKit = null;
    this.ZoneKit = null;
    this.Destinations = null;
    this.pathAnimationTimeout = null;
    this.jmapLoaded = false;
    this.storeUnits = null;
    this.Instruccions = null;
    this.store = null;
    this.activeUserTimer = null;
    this.userInactive = false;
    this.isStoreBlack = false;
    this.wayPointOrigin = null;
    this.wayPointDestination = null;
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectedPoi: PropTypes.object,
    mapDevices: PropTypes.array.isRequired,
    core: PropTypes.object,
    activeVenue: PropTypes.object,
    destinations: PropTypes.array,
    showMap: PropTypes.bool,
    onEndMapInstructions: PropTypes.func.isRequired,
    statusModalBack: PropTypes.bool.isRequired,
    sucTotem: PropTypes.string.isRequired
  };

  static defaultProps = {
    selectedPoi: null,
    core: null,
    activeVenue: null,
    destinations: null,
    showMap: false
  };

  mapHolder = React.createRef();

  componentDidUpdate = (prevProps, prevState) => {
    this.store = null;
    this.wayPointDestination = null;
    this.wayPointOrigin = null;

    const {
      activeVenue,
      core,
      selectedPoi,
      showMap,
      onEndMapInstructions
    } = this.props;

    const { mapLoaded, isAnimatingPath } = this.state;

    if (selectedPoi) {
      this.configUrl();
    }

    // load map after we get the activeVenue and core
    if (
      activeVenue !== null &&
      prevProps.activeVenue === null &&
      core !== null &&
      prevProps.core === null
    ) {
      this.loadMap();
    }

    // if a store is selected and we want to show the map draw path from device to store
    if (
      activeVenue !== null &&
      selectedPoi !== null &&
      mapLoaded &&
      showMap &&
      !prevProps.showMap
    ) {
      this.handleWayfinding();
    }

    // if the map has not load and we already are showing the map and have a selectedPoi -> drawPath
    if (mapLoaded && !prevState.mapLoaded && selectedPoi !== null && showMap) {
      this.handleWayfinding();
    }

    if (!isAnimatingPath && showMap) {
      onEndMapInstructions(true);
    } else {
      onEndMapInstructions(false);
    }
  };

  configUrl = () => {
    // eslint-disable-next-line react/destructuring-assignment
    const { name, suc, poiTypeId, mallId } = this.props.selectedPoi;
    const { sucTotem } = this.props;

    // const sucTotem = url.split('.com/')[1].replace('ResultadoMapa', '');
    // const sucTotem = url.split('3001/')[1].replace('ResultadoMapa', '');

    const qrURL = `https://${urlQRA}/Totem-QR/${mallId}/${name}/${sucTotem}/${suc}/${poiTypeId}`;

    this.setState({
      urlQR: qrURL
    });
  };

  componentWillUnmount() {
    delete this.CONTROL;
    delete this.VENUE;
    delete this.CORE;
    delete this.Destinations;
    delete this.DeviceKit;
    delete this.NavigationKit;
    delete this.MapUiKit;
    delete this.jmapLoaded;
    delete this.Instruccions;
    delete this.store;
    delete this.userInactive;

    jmap.dispatcher.unsubscribe();
    delete jmap.dispatcher._handlers.ready;
    // document.removeEventListener('click', this.closeMenu);
    clearTimeout(this.pathAnimationTimeout);
    clearTimeout(this.activeUserTimer);
  }

  sendEventCategoriesNotSelectable = ({ name }, { externalId }) => {
    ReactGA.event({
      category: `(MallId = ${getMall()}) ${CATEGORY.MAPA};`,
      action: `${ACTION.M_PUNTO_CERCANO};`,
      label: `Categoria = ${name};
              SucMasCercano = ${externalId};
              Totem = ${getTotemData(true).deviceName};`
    });
    ReactGA4.event(`${ACTION.M_PUNTO_CERCANO}`, {
      category: `(MallId = ${getMall()}) ${CATEGORY.MAPA};`,
      label: `Categoria = ${name};
              SucMasCercano = ${externalId};
              Totem = ${getTotemData(true).deviceName};`
    });
  };

  onHandleCloseModalQR = () => {
    this.setState({
      statusModalQR: false,
      statusSecondModalQR: false
    });
  };

  onHandleOpenModalQR = () => {
    this.setState({ statusModalQR: true });
  };

  onHandleOpenSecondModalQR = () => {
    this.setState({ statusModalQR: false, statusSecondModalQR: true });
  };

  handleWayfinding = () => {
    const { selectedPoi } = this.props;

    document.getElementById(MAP_HOLDER_CONTROL).style.zIndex = 1;

    this.clearPath();

    if (this.isStoreBlack) {
      this.CONTROL.styleShapes(this.storeUnits, highlight);
    } else {
      this.CONTROL.resetShapeStyles(this.storeUnits);
    }

    const categories = selectedPoi.categories
      ? selectedPoi.categories[0]
      : selectedPoi;

    if (isCategoryServices(categories, { selectable: true })) {
      const { maps } = this.VENUE;

      const waypoint = getWaypointBySuc(
        selectedPoi.suc,
        this.VENUE.amenities.getAll(),
        maps
      );

      this.store = waypoint;
      this.wayPointDestination = waypoint;
    }

    if (isCategoryServices(categories, { selectable: false })) {
      try {
        const filter = item => item.name === selectedPoi.name;

        const { waypoint } = this.VENUE.getClosestAmenityToWaypoint(
          this.getWaypointFromOrigin(),
          filter
        );

        if (waypoint) {
          this.sendEventCategoriesNotSelectable(categories, waypoint);
        }

        this.store = waypoint;
        this.wayPointDestination = waypoint;
      } catch (e) {
        this.setState({
          openModal: true,
          errorText: `En este momento no podemos mostrar la ruta. Intente nuevamente.`
        });
        console.error('getClosestAmenityToWaypoint', e);

        return;
      }
    }

    if (!this.store) {
      this.store = this.Destinations.find(w => {
        if (w.externalId !== null && selectedPoi.suc !== null) {
          return (
            replaceCharacters(w.externalId) ===
            replaceCharacters(selectedPoi.suc)
          );
        }

        return w.name.toLowerCase() === selectedPoi.name.toLowerCase();
      });
    }

    if (this.store) {
      this.drawPathFromDeviceToStore();
    } else {
      console.error(
        'store externalId not found',
        selectedPoi,
        this.Destinations
      );

      this.setState({
        openModal: true,
        errorText: `No se encontro "${selectedPoi.name}"`
      });
    }
  };

  clearPath = () => {
    this.CONTROL.clearWayfindingPath();
  };

  loadMap = () => {
    try {
      const { activeVenue, core, dispatch } = this.props;

      this.VENUE = activeVenue;
      this.CORE = core;

      this.initMap(); // this.CONTROL is initialized
      this.initMapDestinations();
      this.initMapUI();
      this.initMapDevices(() => {
        this.setState({ mapLoaded: true });
        dispatch(setLoadingMap(false));
      });
      this.CONTROL.enableGenericTapHandler(() => this.handleActiveUser());
      this.CONTROL.enablePanGestures(() => this.handleActiveUser());
      this.CONTROL.disableRotationGestures();
      this.CONTROL.enableScaleGestures(() => this.handleActiveUser());
      this.CONTROL.showAllTextMapLabels();
      this.CONTROL.showAllImageMapLabels();
      this.CONTROL.applyDisplayModeToAllUnits();

      // this.handleActiveUser();
    } catch (err) {
      console.error('centre-map.loadMap', err);
    }
  };

  initMapDestinations = () => {
    const { destinations } = this.props;

    this.Destinations = destinations;
  };

  initMapDevices = async callback => {
    const { mapDevices } = this.props;

    this.DeviceKit = new DeviceKit(this.CORE, this.CONTROL);

    if (mapDevices.length === 0) {
      await this.loadDevices();
    }

    this.applyDeviceOrientation(this.getDeviceByName());

    if (callback) {
      callback();
    }
  };

  initMapUI = () => {
    this.NavigationKit = new NavigationKit(this.CONTROL, {});

    this.MapUiKit = new MapUiKit(this.CONTROL, { padding: [20, 20, 20, 20] });

    const activeStyle = new jmap.Style(ACTIVE_STYLE);
    const inactiveStyle = new jmap.Style(IN_ACTIVE_STYLE);

    this.MapUiKit.renderZoomButtons({
      position: [1, 0],
      activeStyle,
      inactiveStyle
    });

    const destinations = this.CONTROL.activeVenue.destinations._items;

    destinations.forEach(d => {
      const units = this.CONTROL.getUnitsFromDestination(d);
      const { tags } = d;

      // Conditionally color destinations based on tags created in CMS
      const color = new jmap.Style({
        fill: tags.includes(PUBLIC_PROFILE) ? '#555555' : '5b91b9'
      });

      this.CONTROL.styleShapes(units, color);
    });
  };

  initMap = () => {
    const height = this.mapHolder.current
      ? this.mapHolder.current.clientHeight
      : undefined;
    const width = this.mapHolder.current
      ? this.mapHolder.current.clientWidth
      : undefined;

    CONFIG.container = '#map';
    CONFIG.height = height;
    CONFIG.width = width;

    this.CONTROL = new jmap.JController({
      activeVenue: this.VENUE,
      container: '#map', // where the map lives
      forceCanvas: true, // SCALA doesn't support webgl
      height,
      width
    });

    // this.CONTROL.parseAllMaps();
    this.CONTROL.showDefaultMap();

    this.CONTROL.setMapTransform(new jmap.Transform({ scale: 3 }));
  };

  handleActiveUser = () => {
    const { dispatch } = this.props;

    if (this.activeUserTimer) {
      clearTimeout(this.activeUser);
    }

    // the user was inactive but did an action
    if (this.userInactive) {
      // we stop the session timer
      dispatch(setStatus('fetching', true));
      this.userInactive = false;
    }

    // we set a timer to start the session timer again
    this.activeUserTimer = setTimeout(() => {
      this.userInactive = true;
      dispatch(setStatus('fetching', false));
    }, MAP_SESSION_PAUSE_AFTER_LOAD_IN_SECONDS * 1000);
  };

  drawPathFromDeviceToStore = () => {
    const { core } = this.props;

    const storeWaypoint = this.getWaypointFromDestination(this.store);
    const deviceWaypoint = this.getWaypointFromOrigin();

    this.storeUnits = this.CONTROL.getUnitsFromDestination(this.store);
    this.ZoneKit = new ZoneKit(core, this.CONTROL);
    this.isStoreBlack = this.ZoneKit.getZones((error, zones) => {
      if (zones && this.store) {
        return (
          zones._items.findIndex(
            s => s.externalId === this.store.externalId
          ) !== -1
        );
      }

      console.error(error);

      return false;
    });

    this.CONTROL.styleShapes(this.storeUnits, new jmap.Style(COLOR_RED_STORE));

    this.drawPath(deviceWaypoint, storeWaypoint);
  };

  getDeviceByName = () => {
    const { mapDevices } = this.props;

    return mapDevices.find(d => d.externalId === getDevice().totemName);
  };

  applyDeviceOrientation = device => {
    if (!device) {
      const { mapDevices } = this.props;

      console.error(`totem device not found`, mapDevices);

      return;
    }

    const transform = new jmap.Transform({ rotation: -1 * device.heading });

    // Apply rotation transform to map
    this.CONTROL.setMapTransform(transform);
  };

  loadDevices = async () => {
    const { dispatch } = this.props;

    await new Promise((resolve, reject) => {
      this.DeviceKit.getDevices(async (error, devices) => {
        if (error) {
          reject(error);
        }

        const parsedDevices = devices._items.map(item => item._);

        resolve(parsedDevices);
      });
    });

    dispatch(setMapDevices(this.DeviceKit.devices.getAll()));
  };

  handleChangeFloor = mapId => () => {
    const { activeFloor } = this.state;

    if (activeFloor === mapId) {
      return;
    }

    const map = this.VENUE.maps.getById(mapId);
    const path = this.CONTROL.getShapesInLayer(WAYFINDING_PATH, map);
    const bounds = this.CONTROL.getBoundsFromShapes(path);

    this.CONTROL.showMap(map);
    this.CONTROL.fitBoundsInView(
      bounds,
      new jmap.Animation({ duration: 1 }),
      150
    );

    this.setState({ activeFloor: mapId });
  };

  getWaypointFromOrigin = () => {
    try {
      if (this.wayPointOrigin) {
        return this.wayPointOrigin;
      }

      this.wayPointOrigin = this.DeviceKit.getWaypointFromDevice(
        this.getDeviceByName()
      );

      return this.wayPointOrigin;
    } catch (e) {
      this.setState({
        openModal: true,
        errorText: `En este momento no podemos mostrar la ruta. Intente nuevamente.`
      });
      console.error(
        `No se encontró el punto de origen (${getDevice().totemName})`,
        e
      );

      return null;
    }
  };

  getWaypointFromDestination = destination => {
    try {
      if (this.wayPointDestination) {
        return this.wayPointDestination;
      }

      const { maps } = this.VENUE;
      const [wayPointDestination] = maps.getWaypointsByDestination(destination);

      this.wayPointDestination = wayPointDestination;

      return this.wayPointDestination;
    } catch (e) {
      this.setState({
        openModal: true,
        errorText: `En este momento no podemos mostrar la ruta. Intente nuevamente.`
      });
      console.error('getWaypointFromDestination', e);

      return null;
    }
  };

  drawPath = (_from, _to) => {
    try {
      if (!_from) {
        console.error('no device', _from);

        return;
      }

      if (!_to) {
        console.error('no store', _to);

        return;
      }

      // Calculate a wayfinding path
      const path = this.CONTROL.wayfindBetweenWaypoints(_from, _to);

      // this.getInstruccions(path);
      // Create a style for the path
      const pathStyle = new jmap.Style(PATH_STYLE);

      // Draw the static path on the map
      this.CONTROL.drawWayfindingPath(path, pathStyle);

      // Create start & end Icon
      const icons = {
        start: new this.CONTROL.jungle.Icon({
          ...ICON_START,
          point: _from.coordinates
        }),
        end: new this.CONTROL.jungle.Icon({
          ...ICON_END,
          point: _to.coordinates
        })
      };

      // Get the mapView objects to place icons on
      const mapViews = {
        start: this.CONTROL.stage.getMapViewById(path[0].mapId),
        end: this.CONTROL.stage.getMapViewById(path[path.length - 1].mapId)
      };

      // Get the path layers on each map to place the icon
      const pathLayers = {
        start: mapViews.start.guaranteeMapLayer(WAYFINDING_PATH),
        end: mapViews.end.guaranteeMapLayer(WAYFINDING_PATH)
      };

      // Add the icons to each layer
      pathLayers.start.addIcon(icons.start);
      pathLayers.end.addIcon(icons.end);

      // Render the just added Icons
      this.CONTROL.renderCurrentMapView();

      // Set the path to use it again and show level buttons
      if (Array.isArray(path)) {
        this.setState({
          pathToStore: path,
          showLevelButtons: true,
          isAnimatingPath: true
        });
      }

      // animate the static path drawn before
      this.animatePathsAndChangeFloors(path, 0);
    } catch (e) {
      this.setState({
        openModal: true,
        errorText: `En este momento no podemos mostrar la ruta. Intente nuevamente.`
      });
      console.error(
        `No se encontró la ruta desde (${getDevice().totemName})`,
        e
      );
    }
  };

  handleFloorLevel = (currentLevel, nextLvel, pathToStore, index, moverId) => {
    if (currentLevel < nextLvel) {
      this.setState({ messageModal: true });
      this.setState({ moverId });
    } else {
      this.setState({ messageModal: false });
      this.setState({ moverId });
    }

    this.handleOpenModal();

    setTimeout(() => this.handleCloseModal(), 3000);

    setTimeout(
      () =>
        //   // Call this same function but for the next level
        this.animatePathsAndChangeFloors(pathToStore, index + 1),
      3500
    );
  };

  handleOpenModal = () => {
    this.setState({ setOpen: true });
  };

  handleCloseModal = () => {
    setTimeout(this.setState({ setOpen: false }), 2000);
  };

  // pathToStore[]: array of paths between waypoints index: current path
  animatePathsAndChangeFloors = (pathToStore, index) => {
    // if we still have paths to animate
    if (pathToStore.length > index) {
      // Set current activeFloor
      this.setState({
        activeFloor: pathToStore[index].mapId,
        isAnimatingPath: false
      });

      // get the current map(level)
      const map = this.VENUE.maps.getById(pathToStore[index].mapId);
      const path = this.CONTROL.getShapesInLayer(WAYFINDING_PATH, map);
      const bounds = this.CONTROL.getBoundsFromShapes(path);

      // show the map(level)
      this.CONTROL.showMap(map);
      // make sure we see the full path
      this.CONTROL.fitBoundsInView(
        bounds,
        new jmap.Animation({ duration: 1 }),
        150
      );
      // Animate initial path for 5 seconds
      path[0].animate(5);

      // divido las intrucciones por piso, subir a las escaleras cuenta // devido la duracion de la animacion con el numero de instrucciones del piso

      if (this.pathAnimationTimeout) {
        clearTimeout(this.pathAnimationTimeout);
      }

      // Set the next floor to start in 7 seconds
      this.pathAnimationTimeout = setTimeout(() => {
        const currentLevel = this.CONTROL.showMap(map).currentFloor.level;
        const level = pathToStore.map(a => a.seq);
        const nextLvel = level[level.length - 1];
        const { moverId } = pathToStore[index].mover;

        if (pathToStore[index].mover.typeName === ESCALATOR) {
          this.handleFloorLevel(
            currentLevel,
            nextLvel,
            pathToStore,
            index,
            moverId
          );
        } else if (pathToStore[index].mover.typeName === STAIRS) {
          this.handleFloorLevel(
            currentLevel,
            nextLvel,
            pathToStore,
            index,
            moverId
          );
        } else if (pathToStore[index].mover.typeName === ELEVATOR) {
          this.handleFloorLevel(
            currentLevel,
            nextLvel,
            pathToStore,
            index,
            moverId
          );
        } else {
          this.setState({ isAnimatingPath: false });
          this.animatePathsAndChangeFloors(pathToStore, index + 1);
        }
      }, 6000);
    } else {
      // there are no more paths
      // this.setState({ isAnimatingPath: false });

      // const { statusSecondModalQR } = this.state;

      // if (statusSecondModalQR) {
      //   this.setState({ statusModalQR: false });
      // }

      const here = window.location.href;

      if (here.indexOf('ResultadoMapa') > -1) {
        this.setState({
          statusSecondModalQR: false,
          statusModalQR: true,
          isAnimatingPath: false
        });
      }
    }
  };

  handleRepeatPathAnimation = () => {
    const { pathToStore } = this.state;
    const { selectedPoi } = this.props;

    this.setState({ isAnimatingPath: true });

    this.animatePathsAndChangeFloors(pathToStore, 0);

    ReactGA.event({
      category: `(MallId = ${getMall()}) ${CATEGORY.MAPA};`,
      action: `${ACTION.M_REPETIR_RUTA};`,
      label: `Poi = ${selectedPoi.name}; Totem = ${
        getTotemData(true).deviceName
      };`
    });
    ReactGA4.event(`${ACTION.M_REPETIR_RUTA}`, {
      category: `(MallId = ${getMall()}) ${CATEGORY.MAPA};`,
      label: `Poi = ${selectedPoi.name}; Totem = ${
        getTotemData(true).deviceName
      };`
    });
  };

  getInstruccions = path => {
    const mPerPixel = 0.05962332392785686;

    const textInstructions = this.NavigationKit.createInstructionsFromPath(
      path
    ).map(i => {
      const t = {
        text: '',
        wp: i.decisionPoint ? i.decisionPoint.id : i.completionPoint.id,
        dest: null
      };

      if (i.surroundingElements) {
        const destArrays = i.surroundingElements.shapes.map(
          s => s.shape.meta.destinationIds
        );
        const closestDests = destArrays.filter(a => a.length);
        const prepend =
          i.direction === 'Left' || i.direction === 'Right' ? 'Turn ' : '';

        if (closestDests.length) {
          const dest = this.VENUE.destinations.getById(closestDests[0][0]);

          t.dest = dest.id;
          t.text = `${prepend}${i.direction} at ${dest.name}`;
        } else {
          const distance = Math.round(i.pixelDistance * mPerPixel * 100) / 100;

          t.text = `${prepend}${i.direction} in ${distance} meters`;
        }
      } else {
        t.text = i.text;
      }

      return t;
    });

    let currInstructions = [...textInstructions];

    // separo las instrucciones por nivel
    let instructionsFirst = [];
    let instructionsSecond = [];
    let instructionsThird = [];

    // only 1 level so all the instructions are from the first
    if (path.length === 1) {
      instructionsFirst = [...currInstructions];
    }

    // there are 2 levels so there is one Escalator
    if (path.length === 2) {
      // get the index of the first escalator instruction //  + 1 = we include the escalator instruction
      const firstEscaletorIndex =
        currInstructions.findIndex(
          // Teke the Escalator is taken from the instructionss
          i => i.text.indexOf('Take the Escalator') !== -1
        ) + 1;

      // we get the instructions from the first level
      instructionsFirst = currInstructions.slice(0, firstEscaletorIndex);
      // the second level instructions are form the escalator to the end
      instructionsSecond = currInstructions.slice(firstEscaletorIndex);
    }

    // there are 3 levels in the path so we have 2 escalators
    if (path.length === 3) {
      // get the first escaletor index
      const firstEscaletorIndex =
        currInstructions.findIndex(
          i => i.text.indexOf('Take the Escalator') !== -1
        ) + 1;

      instructionsFirst = currInstructions.slice(0, firstEscaletorIndex);

      // we remove the first set of instructions
      currInstructions = [...currInstructions.slice(firstEscaletorIndex)];

      // get the second escalator //  + 1 = we include the escalator instruction
      const secondEscalatorIndex =
        currInstructions.findIndex(
          i => i.text.indexOf('Take the Escalator') !== -1
        ) + 1;

      // get the second instruction set
      instructionsSecond = currInstructions.slice(0, secondEscalatorIndex);
      // from the second escalator to the end
      instructionsThird = currInstructions.slice(secondEscalatorIndex);
    }

    this.Instruccions = {
      first: instructionsFirst,
      second: instructionsSecond,
      third: instructionsThird
    };
  };

  handleCloseModalError = () => {
    const { dispatch, selectedPoi } = this.props;

    this.setState({ openModal: false });
    dispatch(showHideMap(false));
    dispatch(push(`${selectedPoi.originPath}`));
    document.getElementById(MAP_HOLDER_CONTROL).style.zIndex = -10;
  };

  handleShowInstructions = (levelInstruccions, animationDuration) => {
    console.log('handleShowInstructions', levelInstruccions, animationDuration);
  };

  handleIsLevelActive = mapId => {
    const { activeFloor } = this.state;

    if (activeFloor === mapId) {
      return 'active';
    }

    return '';
  };

  handleIsButtonDisabled = floorId => {
    const { isAnimatingPath, pathToStore } = this.state;

    // if we are animating -> disable level buttons
    if (isAnimatingPath) {
      return true;
    }

    // if the level is not in the path -> disable that button
    const isNotOnPath =
      pathToStore.find(f => f.mapId === floorId) === undefined;

    return isNotOnPath;
  };

  render() {
    const {
      showLevelButtons,
      isAnimatingPath,
      showInstructions,
      openModal,
      errorText,
      setOpen,
      messageModal,
      moverId,
      urlQR
    } = this.state;

    const { showMap, statusModalBack } = this.props;

    if (statusModalBack) {
      this.setState({ statusModalQR: false, statusSecondModalQR: false });
    }

    return (
      <div id='map-cont' className='Map-container'>
        <div
          className='lengua'
          onClick={() => this.onHandleOpenSecondModalQR()}
        >
          <img src={iconQR} alt='' />
          <span>Tu ruta en tu celular</span>
        </div>
        <ModalError
          open={showMap && openModal}
          text={errorText}
          onClose={this.handleCloseModalError}
        />
        {isAnimatingPath && showInstructions && (
          <div className='path-instructions'>
            <div className='image'> imagen</div>
            <div className='text'>suba la escalera</div>
          </div>
        )}
        {showLevelButtons && (
          <React.Fragment>
            <div className='floor-levels'>
              {BUTTONS_FLOORS.map(items => (
                <ButtonFloor
                  key={items.floor}
                  items={items}
                  onHandleChangeFloor={this.handleChangeFloor}
                  onHandleIsLevelActive={this.handleIsLevelActive}
                  onHandleIsButtonDisabled={this.handleIsButtonDisabled}
                />
              ))}
            </div>

            <div className='repeat-route-button'>
              <Button
                color='primary'
                variant='contained'
                disabled={isAnimatingPath}
                onClick={this.handleRepeatPathAnimation}
              >
                <div className='txt-icono'>
                  <Trans i18nKey='map_repeat_route' />
                </div>
              </Button>
            </div>
          </React.Fragment>
        )}
        <div className='Map-warning'>
          <span>
            <Trans i18nKey='map_loading' />
          </span>
        </div>
        <div id='map' ref={this.mapHolder} className='Map-holder' />

        <ModalQR
          urlFinish={urlQR}
          // eslint-disable-next-line react/destructuring-assignment
          open={this.state.statusModalQR}
          // eslint-disable-next-line react/destructuring-assignment
          statusSecond={this.state.statusSecondModalQR}
          openModalSecond={this.onHandleOpenSecondModalQR}
          close={this.onHandleCloseModalQR}
        />
        {showMap && (
          <ModalAnimations
            setOpen={setOpen}
            onHandleClose={this.handleClose}
            moverId={moverId}
            messageModal={messageModal}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

const mapStateToProps = state => ({
  language: state.app.data.language,
  selectedPoi: state.lists.data.selectedPoi,
  showMap: state.app.data.showHideMap,
  mapDevices: state.lists.data.mapDevices
});

export default connect(mapStateToProps, mapDispatchToProps)(CentreMap);
