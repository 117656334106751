import React from 'react';
import { Modal, Fade, Button, Backdrop } from '@material-ui/core';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { isMobile } from 'react-device-detect';

import envVariables from '../../config/envVariables';
import { ACTION, CATEGORY } from '../../constants/googleAnalytics';
import { getMall, getTotemData } from '../../config/kiosk';
import ClockIcon from '../../assets/imgs/tiempo.svg';

import styles from './styles';
import './modalInactivity.scss';

const COUNTDOWN_DURATION_IN_SECONDS = envVariables.inactivityCountdown;
const SESSION_DURATION_IN_SECONDS = envVariables.sessionDuration;

class ModalInactividad extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: COUNTDOWN_DURATION_IN_SECONDS,
      open: false
    };

    this.inactivityTimeout = null;
    this.timerCountdown = null;
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    onUserAction: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    endMapInstructions: PropTypes.bool.isRequired,
    statusAnotherModals: PropTypes.func.isRequired
  };

  componentDidUpdate = () => {
    const { endMapInstructions } = this.props;

    if (endMapInstructions) {
      this.handleUserAction();
    }
  };

  handleClose = goTo => {
    const { onClose } = this.props;

    onClose(goTo);
    this.stopTimer();
    this.handleStopInactivityTimeout();
    this.setState({ open: false, timer: COUNTDOWN_DURATION_IN_SECONDS });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.statusAnotherModals(false);
  };

  handleTimer = () => {
    const { timer } = this.state;

    if (timer === 0) {
      this.handleClose('');
      this.stopTimer();
    } else {
      this.setState(prevState => ({ timer: prevState.timer - 1 }));
    }
  };

  stopTimer = () => {
    if (this.timerCountdown) {
      clearInterval(this.timerCountdown);
    }
  };

  startTimer = () => {
    if (this.timerCountdown) {
      this.stopTimer();
    }

    this.timerCountdown = setInterval(() => {
      this.handleTimer();
    }, 1000);
  };

  handleStartInactivityTimeout = () => {
    if (!isMobile) {
      const sessionDuration = SESSION_DURATION_IN_SECONDS * 1000;

      this.handleStopInactivityTimeout();

      this.inactivityTimeout = setTimeout(() => {
        const { fetching } = this.props;

        if (fetching) {
          this.handleStartInactivityTimeout();

          return;
        }

        this.setState({ open: true });
        // eslint-disable-next-line react/destructuring-assignment
        this.props.statusAnotherModals(true);
        this.startTimer();
      }, sessionDuration);
    }
  };

  handleStopInactivityTimeout = () => {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
      this.inactivityTimeout = null;
    }
  };

  handleUserAction = () => {
    const { onUserAction } = this.props;
    const { open } = this.state;

    if (open) {
      return;
    }

    if (this.inactivityTimeout) {
      onUserAction();
    }

    this.handleStartInactivityTimeout();
  };

  handleExtendSesion = () => {
    this.stopTimer();
    this.handleStartInactivityTimeout();
    this.setState({ open: false, timer: COUNTDOWN_DURATION_IN_SECONDS });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.statusAnotherModals(false);

    ReactGA.event({
      category: `(MallId = ${getMall()}) ${CATEGORY.MODAL_INACTIVIDAD};`,
      action: `${ACTION.MI_CLICK_EN_SI};`,
      label: `Totem = ${getTotemData(true).deviceName};`
    });
    ReactGA4.event(`${ACTION.MI_CLICK_EN_SI}`, {
      category: `(MallId = ${getMall()}) ${CATEGORY.MODAL_INACTIVIDAD};`,
      label: `Totem = ${getTotemData(true).deviceName};`
    });
  };

  render() {
    const { children } = this.props;
    const { timer, open } = this.state;

    return (
      <div
        onClickCapture={this.handleUserAction}
        onTouchStart={this.handleUserAction}
        style={{ height: '100%' }}
      >
        {children}
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open}
          onClose={() => this.handleClose('')}
          closeAfterTransition
          disableBackdropClick
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div style={styles.modal} className='inactivity-modal'>
              <img className='modal-icon' src={ClockIcon} alt='time_icon' />
              <div className='modal-description'>
                <Trans i18nKey='modal_inactividad_time_left_modal' />
                <b>{` ${timer} `}</b>
                <b>
                  {timer === 1 ? (
                    <Trans i18nKey='modal_inactividad_second' />
                  ) : (
                    <Trans i18nKey='modal_inactividad_seconds' />
                  )}
                </b>
              </div>
              <h1 className='modal-title'>
                <Trans i18nKey='modal_inactividad_titulo_modal' />
              </h1>
              <div>
                <Button
                  onClick={() => this.handleClose('home')}
                  className='modal-btn-yes'
                >
                  <span>
                    <Trans i18nKey='modal_inactividad_boton_si' />
                  </span>
                </Button>
              </div>
              <div>
                <Button
                  onClick={this.handleExtendSesion}
                  className='modal-btn-no'
                >
                  <span>
                    <Trans i18nKey='modal_inactividad_boton_no' />
                  </span>
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default ModalInactividad;
