import React from 'react';
import { connect } from 'react-redux';
import { Icon } from '@material-ui/core/';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import StepOne from '../../assets/images/step1.png';
import StepTwo from '../../assets/images/step2.png';
import StepThree from '../../assets/images/step3.png';
import imageTicket from '../../assets/images/Ticket.png';
import { ACTION, CATEGORY, LABEL } from '../../constants/googleAnalytics';
import './index.scss';
import { showHideMap } from '../../actions/map';
import { selectPoi } from '../../actions/lists';
import { getMall, getTotemData } from '../../config/kiosk';

class LostTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    // eslint-disable-next-line react/require-default-props
    dispatch: PropTypes.func
  };

  handleBack = () => {
    const { dispatch } = this.props;

    dispatch(push(`/${getTotemData(true).deviceName}/index`));
  };

  handleGoTOMap = () => {
    const { dispatch } = this.props;
    const kioskOrigin = getTotemData(true).deviceName;
    const item = {
      id: '1096',
      name: 'Oficina Parking',
      icon: 'icon-Automotriz',
      isSelectable: false,
      mallId: 1,
      poiTypeId: 5,
      slug: null,
      storeCount: 0,
      urlLanding: null,
      bgColorClass: 'bg-GRIS',
      originPath: `/${kioskOrigin}/PerdiMiTicket`,
      destinPath: `/${kioskOrigin}/ResultadoMapa`,
      mapTitle: 'SERVICIOS',
      showMapa: true,
      atm: false
    };

    ReactGA.event({
      category: `(MallId = ${getMall()}) ${CATEGORY.DIRECTORIO_SERVICIOS};`,
      action: `${ACTION.S_CATEGORIA_SELECCIONADA};`,
      label: `Categoría = ${item.name}; Totem = ${kioskOrigin}; ${LABEL.NOT_SELECTABLE}`
    });
    ReactGA4.event(`${ACTION.S_CATEGORIA_SELECCIONADA}`, {
      category: `(MallId = ${getMall()}) ${CATEGORY.DIRECTORIO_SERVICIOS};`,
      label: `Categoría = ${item.name}; Totem = ${kioskOrigin}; ${LABEL.NOT_SELECTABLE}`
    });

    dispatch(selectPoi(item));
    dispatch(showHideMap(true));

    dispatch(push(`/${kioskOrigin}/ResultadoMapa`));
  };

  render() {
    return (
      <div className='mobile-map'>
        <div className='cabecera bg-MENTA-2 acomodaTitulo'>
          <div className='letraVolver barra bg-MENTA'>
            <div
              className='btn-volver'
              onClickCapture={this.handleBack}
              style={{ color: 'white' }}
            >
              <Icon className='m-r'>arrow_back</Icon>
              <Trans i18nKey='busqueda_patente_btn_inicio' />
            </div>
          </div>
          <div className='titulo-cabecera-ticket'>
            <div style={{ color: 'white' }}>
              Que <b>pasa</b> si pierdo mi ticket
            </div>
          </div>
        </div>
        <div className='contenedor-normal'>
          <div className='titulo'>¿Qué pasa si pierdo mi ticket?</div>
          <div className='imgTicket'>
            <img src={imageTicket} alt='ticket' />
          </div>
          <div className='filaTicket'>
            <div className='numeroTicket'>
              <img src={StepOne} alt='ticket' className='imgNumero' />
            </div>
            <div className='textoTicket'>
              <b>¡No te preocupes!</b> No se te cobrara nada extra por el
              extravió de tu ticket.
            </div>
          </div>
          <div className='filaTicket'>
            <div className='numeroTicket'>
              <img src={StepTwo} alt='ticket' className='imgNumero' />
            </div>
            <div className='textoTicket'>
              Tienes que llevar el <b>Padrón</b> o el{' '}
              <b>permiso de circulación y tu carnet</b> a la oficina ubicada:{' '}
              <div className='linkTicket' onClick={() => this.handleGoTOMap()}>
                aquí
              </div>
            </div>
          </div>
          <div className='filaTicket'>
            <div className='numeroTicket'>
              <img src={StepThree} alt='ticket' className='imgNumero' />
            </div>
            <div className='textoTicket'>
              Allí se te <b>reimprimirá tu ticket</b> y ¡listo! ya podrás ir a
              pagar online o en cajero.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

const mapStateToProps = state => ({
  selectedPoi: state.lists.data.selectedPoi
});

export default connect(mapStateToProps, mapDispatchToProps)(LostTicket);
